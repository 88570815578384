import * as React from "react";
import sinchStImg from "../images/sinch.2_ipad_resized_copy.png";
import sinchStVideo from "../images/Sinch_ST_Video.mp4";
import { pageStyles } from "../styles/helpers";
import "../styles/global-styles.css";

// styles
const imageStyles = {
  marginTop: "-23px",
  marginBottom: 0,
  width: "100%",
};
const videoStyles = {
  marginTop: "-4px",
  marginBottom: 0,
  width: "100%",
};

// markup
const SinchST = () => {
  return (
    <main style={pageStyles}>
      <title>Sinch Specs</title>
      <img style={imageStyles} src={sinchStImg} />
      <video
        style={videoStyles}
        crossOrigin="anonymous"
        controls={false}
        autoPlay
        loop
        muted
      >
        <source src={sinchStVideo} type="video/mp4" />
      </video>
    </main>
  );
};

export default SinchST;
